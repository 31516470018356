import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import util from 'util'
// modules
import user from './modules/user'
//-------------------------------------mail
import mail from './modules/mails/mail'
import sendMail from './modules/sendMail/sendMail'
import rightMenu from './modules/mails/rightMenu'
import mailAdvance from './modules/mails/mail_advance'
import mailDetail from './modules/mails/mail_detail'
import folders from './modules/mails/folders'
import template from './modules/mails/template'
import contactNav from './modules/contact/contactNav'
import contactList from './modules/contact/contactList'
import shipAdvance from './modules/ship/ship_advance'
import basicData from './modules/basicData'
import role from './modules/roles/role'
import users from './modules/users/users'
import upload from './modules/upload/upload'
import storageConstant from '@/common/constants/local-storage.constant';

import notification from './modules/notification/notification.js';
import calendar from './modules/calendar/calendar.js';
import task from './modules/calendar/task.js';
import undo from './modules/undo';

Vue.use(Vuex);

const state = {
  lines: util.storeInLocalStorage.get(storageConstant.LINES) || [],
  sendableLines: util.storeInLocalStorage.get(storageConstant.SENDABLE_LINE) || [],
  lines_all: {
    lines: [],
    full_lines: [],
    unread_count: 0,
  },
  threadEmails: [],
  maxThreadEmail: 1000,
  loadingThread: false,
  originalEmailId: null,
  nestedFolderSelected: {},
  rightClickFolderData: {},
  folderToCopy: null,
  mailDragged: [],
  contactDragged: [],
  online: true,
  emailTobeRead: localStorage.getItem(storageConstant.EMAIL_TO_BE_READ) ? JSON.parse(localStorage.getItem(storageConstant.EMAIL_TO_BE_READ)) : [],
  configuredTags: [],
  expandingNavItems: localStorage.getItem(storageConstant.EXPANDING_NAV_ITEMS) ? JSON.parse(localStorage.getItem(storageConstant.EXPANDING_NAV_ITEMS)) : [],
  helpCenter: {
    hasNewVersion: false,
    userHelpCenterVersion: 0
  }
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    mail,
    user,
    sendMail,
    rightMenu,
    basicData,
    mailAdvance,
    mailDetail,
    shipAdvance,
    folders,
    template,
    contactNav,
    contactList,
    role,
    users,
    upload,
    notification,
    calendar,
    task,
    undo
  },
});
