<template>
  <div
    ref="wrap"
    class="atwho-wrap"
    @compositionstart="handleCompositionStart"
    @compositionend="handleCompositionEnd"
    @input="handleInput"
    @keydown="handleKeyDown"
  >
    <div v-if="atwho" ref="panel" class="atwho-panel" :style="atwhoStyle">
      <div class="atwho-guide">
        <span></span>
        <span class="shortcuts">
          <i class="iconfont ch-icon-updown"></i>
          to navigate
          <i class="iconfont ch-icon-enter_sign" style="font-size: 20px"></i>
          to select
        </span>
      </div>
      <ul class="atwho-ul" ref="suggestion">
        <!--{{atwho.list}}-->
        <li
          v-for="(item, index) in atwho.list"
          class="atwho-li"
          :class="isCur(index) && 'atwho-cur'"
          :ref="isCur(index) && 'cur'"
          :data-index="index"
          @mouseenter="handleItemHover"
          @click.prevent.stop="handleItemClick"
          :key="`${index}${item}`"
        >
          <!--#tag-->
          <div v-if="addtag" class="list-item">
            <div class="userName">#{{ item }}</div>
          </div>
          <!--@user-->
          <div v-else class="list-item">
            <div class="list-icon">
              <span class="tagIcon" v-if="item.type === 'user'">
                <avatar
                  class="avatar"
                  :fullname="item.display_name"
                  :avatarURL="item.avatar"
                  :width="30"
                ></avatar>
              </span>
              <span class="tagIcon" v-else
                ><i class="iconfont-contacts"></i
              ></span>
            </div>
            <div class="displayName">
              <div v-if="item.type === 'user'" class="userName">
                <span>{{ item.user_name }}</span>
                <span class="name" v-if="item.display_name !== ''">{{
                  item.display_name
                }}</span>
              </div>
              <div v-else>{{ item.user_name }} ({{ item.count }})</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import {
  closest,
  getOffset,
  getPrecedingRange,
  getRange,
  applyRange,
  scrollIntoView
} from "./util";
import { mapGetters, mapState } from "vuex";
import api from "../../../../fetch/api.js";
import debounce from "lodash/debounce";
import avatar from "@/pages/components/avatar.vue";
import { of } from 'rxjs';
import { popupPosition } from '@/mixins';

export default {
  name: "At",
  props: {
    at: {
      type: String,
      default: "@"
    },
    tag: {
      type: String,
      default: "#"
    },
    avoidEmail: {
      type: Boolean,
      default: true
    },
    hoverSelect: {
      type: Boolean,
      default: true
    },
    nameKey: {
      type: String,
      default: ""
    },
    filterMatch: {
      type: Function,
      default: (name, chunk) => {
        return name.toLowerCase().indexOf(chunk.toLowerCase()) > -1;
      }
    },
    deleteMatch: {
      type: Function,
      default: (name, chunk) => {
        return name === chunk;
      }
    },
    type: {
      type: String,
      requited: true
    }
  },
  data() {
    return {
      hasComposition: false,
      atwho: null,
      atwhoStyle: { top: 0, left: 0 },
      addtag: false,
      incompletedString: "",
      tags: []
    };
  },
  mixins: [popupPosition],
  methods: {
    exactSort(array, str, property) {
      return array.sort((a, b) => {
        const currentString = property && a?.[property] ? a?.[property] : a;
        const nextString = property && b?.[property] ? b?.[property] : b;
        if (currentString === str) return -1;
        if (nextString === str) return 1;
        if (currentString.startsWith(str) && !nextString.startsWith(str)) return -1;
        if (!currentString.startsWith(str) && nextString.startsWith(str)) return 1;

        const aContains = currentString.includes(str);
        const bContains = nextString.includes(str);

        if (aContains && bContains) {
          return currentString.localeCompare(nextString);
        }

        if (aContains) return -1;
        if (bContains) return 1;

        return 0;
      });
    },
    itemName(v) {
      if (this.addtag) {
        return v;
      } else {
        return v.user_name;
      }
    },
    isCur(index) {
      return index === this.atwho.cur;
    },

    handleItemHover(e) {
      if (this.hoverSelect) {
        this.selectByMouse(e);
      }
    },
    handleItemClick(e) {
      this.selectByMouse(e);
      this.insertItem();
    },
    handleDelete(e) {
      const range = getPrecedingRange();
      if (range) {
        const { at, tag, members, deleteMatch, itemName } = this;
        const text = range.toString();
        const index = text.lastIndexOf(at);
        const tagindex = text.lastIndexOf(tag);
        const teamIndex = text.indexOf("TEAM");
        if (teamIndex > 1) {
          e.preventDefault();
          e.stopPropagation();
          const r = getRange();
          if (r) {
            r.setStart(r.endContainer, teamIndex - 2);
            applyRange(r);
            document.execCommand("insertHtml", true, "");
          }
        }

        if (tagindex > -1) {
          const tagr = getRange();
          if (tagr.startOffset == 2){
            document.getElementById('chartDesktag').parentNode.removeChild(document.getElementById('chartDesktag'));
          }
        }

        if (index > -1) {
          const chunk = text.slice(index + 1);
          const has = members.some(v => {
            const name = itemName(v);
            return deleteMatch(name, chunk);
          });
          if (has) {
            e.preventDefault();
            e.stopPropagation();
            const r = getRange();
            if (r) {
              r.setStart(r.endContainer, index);
              applyRange(r);
              document.execCommand("insertHtml", true, "");
            }
          }
        }
      }
    },
    handleKeyDown(e) {
      const { atwho, addtag } = this;
      if (e.key === "#") {
        this.insertTag();
      } else if ((e.keyCode === 32) && (addtag == true)) {
        document.execCommand("insertHtml", 0, `<span> </span>`);
      }
      if (atwho) {
        if (e.keyCode === 38 || e.keyCode === 40) {
          // ↑/↓
          if (!(e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            e.stopPropagation();
            this.selectByKeyboard(e);
          }
          return;
        }
        if (e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
          this.insertItem();
          return;
        }
        if (e.keyCode === 27) {
          this.closePanel();
          return;
        }
      }

      const isChar = e.keyCode >= 48 && e.keyCode <= 90;
      if (isChar) {
        setTimeout(this.handleInput, 50);
      }

      if (e.keyCode === 8) {
        this.handleDelete(e);
      }
    },

    // compositionStart -> input -> compositionEnd
    handleCompositionStart() {
      this.hasComposition = true;
    },
    handleCompositionEnd() {
      this.hasComposition = false;
      this.handleInput();
    },
    handleInput: debounce(async function() {
      if (this.hasComposition) {
        this.incompletedString = "";
        return;
      }
      const range = getPrecedingRange();
      if (range) {
        const { at, tag, avoidEmail } = this;
        let show = true;
        const text = range.toString();
        const atindex = text.lastIndexOf(at);
        const tagindex = text.lastIndexOf(tag);
        if (atindex < 0 && tagindex < 0) {
          show = false;
        }
        if (tagindex > -1) {
          this.addtag = true;
          this.incompletedString = text.split("#")[1];
        } else {
          this.addtag = false;
          this.incompletedString = text.split("@")[1];
        }

        //suggest Tags
        if (this.addtag) {
          const suggestTag = await this.getSuggestTags();
          this.tags = this.exactSort(suggestTag, this.incompletedString);
        }

        const prev = text[atindex - 1];
        const chunk = this.addtag
          ? text.slice(tagindex + 1)
          : text.slice(atindex + 1);

        if (avoidEmail) {
          if(/^[a-z0-9]$/i.test(prev)) show = false;
        }

        if (/^\s/.test(chunk)) show = false;

        if (!show) {
          this.closePanel();
          return;
        }
        const { members } = this;
        const matched = members.filter((v) => {
          return (
            v?.display_name?.toLowerCase()?.includes(chunk?.toLowerCase()) ||
            v?.user_name?.toLowerCase()?.includes(chunk?.toLowerCase())
          );
        });

        if (matched.length) {
          const index = this.addtag ? tagindex : atindex;
          this.openPanel(matched, range, index);
        } else {
          this.closePanel();
        }
      }
    }, 300),
    closePanel() {
      this.$emit("hideShow");
      if (this.atwho) {
        this.atwho = null;
      }
    },
    createSortedListByUsername(list, type) {
      return this.exactSort(list
        .filter(l => {
          if (!l.user_name) return false;

          l.sortName = l.user_name.toLowerCase().trim();
          return l.type === type
        }), this.incompletedString, 'sortName')
    },
    openPanel(list, range, offset) {
      this.$emit("atShow");

      let newList = list;
      if (!this.addtag) {
        const roles = this.createSortedListByUsername(list, "role");
        const users = this.createSortedListByUsername(list, "user");
        newList = [...roles, ...users];
      }

      this.atwho = {
        range,
        offset,
        list: newList,
        cur: 0
      };
      this.$nextTick(_ => {
        const position = this.computePosition(this.$refs.wrap, this.$refs.panel, 'top');
        position && (this.atwhoStyle = {
          top: position.top + 'px',
          left: position.left + 'px',
          right: position.right+'px'
        })
      })
    },

    scrollToCur() {
      const el = this.$refs.cur[0];
      const listEl = this.$refs.suggestion;
      if(!el || !listEl)
        return;
      if(listEl.getBoundingClientRect().bottom - el.getBoundingClientRect().bottom <= 20
        || el.getBoundingClientRect().top - listEl.getBoundingClientRect().top <= 20
      )
      el.scrollIntoView({ behavior: 'smooth', block:'center' })
    },
    selectByMouse(e) {
      const el = closest(e.target, d => d.dataset.index);
      const cur = +el.dataset.index;
      this.atwho = {
        ...this.atwho,
        cur
      };
    },
    selectByKeyboard(e) {
      let offset = e.keyCode === 38 ? -1 : 1;
      const { atwho, members } = this;
      let { cur, list } = atwho;
      if (cur + offset > list.length - 1) {
        cur = list.length - 1;
        offset = 0
      }
      if (cur + offset < 0) {
        cur = 0;
        offset = 0;
      }
      this.atwho = {
        ...this.atwho,
        cur: cur + offset
      };
      this.$nextTick(() => {
        this.scrollToCur();
      });
    },
    insertItem() {
      const { range, offset, list, cur } = this.atwho;
      const { itemName } = this;
      let string = "";
      const r = range.cloneRange();
      r.setStart(r.endContainer, offset);
      applyRange(r);
      applyRange(r);
      if (this.addtag) {
        string = `<span style='font-weight:bold' id="chartDesktag">#${itemName(list[cur])}</span> `;
      } else {
        if (list[cur].type === "role") {
          string =
            `<strong class="department" data-uname="${itemName(
              list[cur]
            )}" data-userid="${list[cur].id}">@${itemName(list[cur])}` +
            `<span class="teamIcon" style="` +
            this.teamIconStyle +
            `">&nbsp&nbspTEAM&nbsp&nbsp</span>` +
            `</strong> `;
        } else {
          string = `<strong class="user" data-userid="${
            list[cur].id
          }">@${itemName(list[cur])}</strong> `;
        }
      }
      document.execCommand("insertHtml", 0, string);
      this.$emit("atCompleted");
    },
    insertTag() {
      const range = getPrecedingRange();
      if (range) {
        const { tag } = this;
        let text = range.toString();
        const index = text.lastIndexOf(tag);
        const spaceindex = text.lastIndexOf(" ");
        const r = range.cloneRange();
        if (index > -1 && spaceindex < index) {
          text = text.slice(index);
          r.setStart(r.endContainer, index);
          applyRange(r);
          document.execCommand(
            "insertHtml",
            0,
            `<span style='font-weight:bold' id="chartDesktag">${text}</span>`
          );
          this.addtag = false;
        } else {
          document.execCommand(
            "insertHtml",
            0,
            `<span style='font-weight:bold' id="chartDesktag"> </span>`
          );
          return false;
        }
      }
    },
    async getSuggestTags() {
      if(this.setting_company.limit_hashtag == '1') {
        const filteredTags = this.configuredTags.filter(t => !!t.includes(this.incompletedString));
        return filteredTags;
      }
      const res = await api.suggest_tags({
        tagable_type: this.type,
        key: this.incompletedString
      });
      return res && res.tags || [];
    }
  },
  computed: {
    ...mapState(["configuredTags"]),
    ...mapGetters(["teamMembers", "roles", "setting_company"]),
    members() {
      if (this.addtag) {
        return this.tags;
      } else {
        const roleList = this.roles.roleList;
        const result = [];
        if (roleList && roleList.length > 0) {
          roleList.forEach(item => {
            !item.disable_mention && result.push({
              id: item.id,
              user_name: item.name,
              type: "role",
              count: item.users.length
            });
          });
        }
        if (this.teamMembers && this.teamMembers.length > 0) {
          this.teamMembers.forEach(item => {
            result.push({
              id: item.id,
              user_name: item.user_name,
              display_name: item.name,
              type: "user",
              avatar: item.avatar
            });
          });
        }
        return result;
      }
    },
    teamIconStyle() {
      return (
        "display: inline-block;color: #fff; font-size:12px;transform:scale(0.8);" +
        "font-weight:bolder;background: #66B1CB;border-radius: 2px;line-height:20px;"
      );
    },
    teamStyle() {
      return "position: relative;padding-left: 25px;";
      //          "font-weight:bolder;background: #66B1CB;border-radius: 2px;line-height:20px;"
    }
  },
  mounted() {
    document.body.addEventListener("click", () => {
      this.atwho = null;
    });
  },
  beforeDestroy() {
    document.body.removeEventListener("click", () => {
      this.atwho = null;
    });
  },
  components: {
    avatar
  }
};
</script>

<!--<style lang="scss" src="./At.scss"></style>-->
<style lang="scss" scoped rel="stylesheet/scss">
.atwho-wrap {
  text-align: left;
  width: 100%;
  font-size: 12px;
  .atwho-panel {
    position: fixed;
    z-index: 2;
    background-color: var(--component-color);
    color: var(--on-component-color);
    border: 1px solid var(--border-color);
    overflow: hidden;
    .atwho-guide {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      width: 100%;
      padding: 0 16px;
      color: var(--text-color);
      background: var(--card-color);
      border-bottom: 1px solid var(--border-color);
      .shortcuts {
        i {
          vertical-align: middle;
        }
        svg.icon {
          fill: var(--blue-main);
        }
      }
    }
    .atwho-ul {
      max-height: 250px;
      padding: 0;
      margin: 0;
      list-style: none;
      overflow-y: auto;
      overflow-y: overlay;
    }
    .atwho-li {
      cursor: pointer;
      padding: 0 12px;
      &.atwho-cur {
        background: var(--hover-color);
        color: var(--text-color);
      }
      .list-item {
        display: flex;
        height: 40px;
        align-items: center;
        .list-icon {
          display: flex;
          align-items: center;
          margin-right: 16px;
          .tagIcon {
            display: inline-block;
            width: 32px;
            min-width: 32px;
            text-align: center;
          }
        }
        .displayName {
          width: 100%;
          .userName {
            display: flex;
            justify-content: space-between;
            span {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
