<template>
  <div class="resizable-component">
    <div
      v-for="handle in resizeHandles"
      :key="handle.position"
      :class="['resize-handle', handle.position]"
      @mousedown="initResize($event, handle.position)"
    ></div>
  </div>
</template>

<script>
export default {
  name: "VResizable",
  props: {
    resizeRef: {
      default: null,
    },
    minWidth: {
      default: 0,
      type: Number,
    },
    maxWidth: {
      default: Infinity,
      type: Number,
    },
    minHeight: {
      default: 0,
      type: Number,
    },
    maxHeight: {
      default: Infinity,
      type: Number,
    },
  },
  data() {
    return {
      resizeElement: null,
      startX: 0,
      startY: 0,
      startWidth: 0,
      startHeight: 0,
      startTop: 0,
      startLeft: 0,
      resizeDirection: "",
      isMaximized: false,
      originalWidth: 0,
      originalHeight: 0,
      originalTop: 0,
      originalLeft: 0,
      resizeHandles: [
        { position: "top-left", cursor: "nwse-resize" },
        { position: "top-right", cursor: "nesw-resize" },
        { position: "bottom-left", cursor: "nesw-resize" },
        { position: "bottom-right", cursor: "nwse-resize" },
      ],
    };
  },
  methods: {
    initResize(e, direction) {
      if (!this.resizeElement) return;
      e.stopPropagation();
      e.preventDefault();
      this.resizeDirection = direction;
      this.startX = e.clientX;
      this.startY = e.clientY;
      this.startWidth = this.resizeElement.offsetWidth;
      this.startHeight = this.resizeElement.offsetHeight;
      this.startTop = this.resizeElement.offsetTop;
      this.startLeft = this.resizeElement.offsetLeft;

      document.addEventListener("mousemove", this.resize);
      document.addEventListener("mouseup", this.stopResize);
    },
    resize(e) {
      if (!this.resizeElement) return;
      e.stopPropagation();
      e.preventDefault();
      const dx = e.clientX - this.startX;
      const dy = e.clientY - this.startY;
      let newWidth = this.startWidth;
      let newHeight = this.startHeight;
      let newTop = this.startTop;
      let newLeft = this.startLeft;

      switch (this.resizeDirection) {
        case "top-left":
          newWidth = Math.max(this.startWidth - dx, this.minWidth);
          newHeight = Math.max(this.startHeight - dy, this.minHeight);
          newLeft = this.startLeft + (this.startWidth - newWidth);
          newTop = this.startTop + (this.startHeight - newHeight);
          break;
        case "top-right":
          newWidth = Math.max(this.startWidth + dx, this.minWidth);
          newHeight = Math.max(this.startHeight - dy, this.minHeight);
          newTop = this.startTop + (this.startHeight - newHeight);
          break;
        case "bottom-left":
          newWidth = Math.max(this.startWidth - dx, this.minWidth);
          newHeight = Math.max(this.startHeight + dy, this.minHeight);
          newLeft = this.startLeft + (this.startWidth - newWidth);
          break;
        case "bottom-right":
          newWidth = Math.max(this.startWidth + dx, this.minWidth);
          newHeight = Math.max(this.startHeight + dy, this.minHeight);
          break;
        default:
          break;
      }

      // Constrain to window size
      const maxWidth =
        this.maxWidth !== null
          ? Math.min(this.maxWidth, window.innerWidth - newLeft)
          : window.innerWidth - newLeft;
      const maxHeight =
        this.maxHeight !== null
          ? Math.min(this.maxHeight, window.innerHeight - newTop)
          : window.innerHeight - newTop;

      newWidth = Math.min(newWidth, maxWidth);
      newHeight = Math.min(newHeight, maxHeight);

      // Check if resizing to top direction exceeds screen
      if (newTop < 0) {
        newHeight += newTop; // Adjust height to fit within screen
        newTop = 0; // Align top to the edge of screen
      }
      if (newLeft < 0) {
        newWidth += newLeft; // Adjust width to fit within screen
        newLeft = 0; // Align left to the edge of screen
      }

      this.resizeElement.style.width = `${newWidth}px`;
      this.resizeElement.style.height = `${newHeight}px`;
      this.resizeElement.style.top = `${newTop}px`;
      this.resizeElement.style.left = `${newLeft}px`;
    },
    maximize() {
      if (!this.resizeElement) return;
      if (!this.isMaximized) {
        this.originalWidth = this.resizeElement.offsetWidth;
        this.originalHeight = this.resizeElement.offsetHeight;
        this.originalTop = this.resizeElement.offsetTop;
        this.originalLeft = this.resizeElement.offsetLeft;
        this.resizeElement.style.width = `${window.innerWidth}px`;
        this.resizeElement.style.height = `${window.innerHeight}px`;
        this.resizeElement.style.top = "0px";
        this.resizeElement.style.left = "0px";

        this.isMaximized = true;
        this.$emit('onResize')
      }
    },
    minimize() {
      if (!this.resizeElement) return;
      if (this.isMaximized) {
        this.resizeElement.style.width = `${this.originalWidth}px`;
        this.resizeElement.style.height = `${this.originalHeight}px`;
        this.resizeElement.style.top = `${this.originalTop}px`;
        this.resizeElement.style.left = `${this.originalLeft}px`;

        this.isMaximized = false;
        this.$emit('onResize')
      }
    },
    stopResize() {
      this.$emit('onResize')
      document.removeEventListener("mousemove", this.resize);
      document.removeEventListener("mouseup", this.stopResize);
    },
    togglePositionParserSize() {
      if (this.isMaximized) this.minimize();
      else this.maximize();
    },
  },
  watch: {
    resizeRef: {
      handler(newValue) {
        if (!newValue) return;
        this.resizeElement = newValue?.$el.querySelector(
          ".ivu-modal-content-drag"
        );
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.resize-handle {
  width: 10px;
  height: 10px;
  position: absolute;
}

.resize-handle.top-left {
  top: 0;
  left: 0;
  cursor: nwse-resize;
}

.resize-handle.top-right {
  top: 0;
  right: 0;
  cursor: nesw-resize;
}

.resize-handle.bottom-left {
  bottom: 0;
  left: 0;
  cursor: nesw-resize;
}

.resize-handle.bottom-right {
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
}
</style>
