/**
 * Created by zxy on 2017/10/18.
 */
import util from 'util'
import {SET_MAIL_ADVANCE} from '../../mutation-type'

const advance_search = util.storeInLocalStorage.get('mailAdvance') || {};

const state = {
  search_in_enable: advance_search.search_in_enable || false,
  line_ids: advance_search.line_ids || '',
  match_type: advance_search.match_type || 'every',
  mail_scope: advance_search.mail_scope || 'incoming',
  rules: advance_search.rules || ''
};
const getters = {
  mailAdvance: state => state,
};
const mutations = {
  [SET_MAIL_ADVANCE]: (state, params) => {
    state.search_in_enable = params.search_in_enable;
    state.line_ids = params.line_ids;
    state.match_type = params.match_type;
    state.mail_scope = params.mail_scope;
    state.rules = params.rules;
  },
};

const actions = {
  saveMailAdvance({commit}, params){
    params.line_ids = params.line_ids.join(',')
    params.rules = JSON.stringify(params.rules)
    commit(SET_MAIL_ADVANCE, params);
    util.storeInLocalStorage.set('mailAdvance', params);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
